<template>

    <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" sm="12" md="6">

          <v-alert
              prominent
              border="left"
              :type="login_info_type"
              v-if="!authenticated"
            >
              {{login_info}}
            </v-alert>

            <v-card class="mx-auto" v-if="!authenticated">

              <!-- </v-img> -->
                <v-toolbar flat color="transparent">
                    <v-icon large color="accent">fas fa-sign-in-alt</v-icon>
                    <v-toolbar-title class="headline ml-4 primary--text">Anmeldung</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-text-field 
                            v-model="username" 
                            label="Benutzername" 
                            placeholder=" "
                            prepend-icon="fas fa-user"
                            type="text"
                        />
                        <v-text-field 
                            v-model="password" 
                            :type="showPassword ? 'text' : 'password'" 
                            label="Passwort"
                            placeholder=" "
                            prepend-icon="fas fa-key"
                            :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                            @click:append="showPassword = !showPassword"
                        />
                    </v-form>
                    
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="caption">
                    <v-spacer></v-spacer>
                    <v-btn class="my-3" color="primary" rounded outlined @click="handleLogin">Anmelden</v-btn>
                </v-card-actions>
            </v-card>

        </v-col>
    </v-row>
</template>

<script>
import authentication from '@/_auth/auth'
import Vue from 'vue'

export default {

  name: 'Login',

  data () {
    return {
      showPassword: false,
      username: '',
      password: '',

      valid: true,
    }
  },

  mounted() {

    // remove cookies
      Vue.prototype.$session.destroy()

      // remove the axios default header
      delete this.$axios.defaults.headers.common['Authorization']

      // set store
      this.$store.dispatch('store_auth/UPDATE_REDIRECT_URL', '/')
      this.$store.dispatch('store_auth/AUTHOR_SUCCESS', '')

      // clear stores
      this.$store.dispatch('store_assessment/RESET_STATE')
      this.$store.dispatch('store_client/RESET_STATE')
      this.$store.dispatch('store_coach/RESET_STATE')
      this.$store.dispatch('store_dialogs/RESET_STATE')
      this.$store.dispatch('store_guided_task/RESET_STATE')
      this.$store.dispatch('store_introduction/RESET_STATE')
      this.$store.dispatch('store_lection/RESET_STATE')
      this.$store.dispatch('store_loader/RESET_STATE')
      this.$store.dispatch('store_main_messages/RESET_STATE')
      this.$store.dispatch('store_menu/RESET_STATE')
      this.$store.dispatch('store_messages/RESET_STATE')
      this.$store.dispatch('store_quiz/RESET_STATE')
      this.$store.dispatch('store_supervisor/RESET_STATE')
      this.$store.dispatch('store_task/RESET_STATE')

  },

  computed: {

    authenticated() {
      return this.$store.getters['store_auth/get_is_authenticated']
    },

    loading() {
      return this.$store.getters['store_loader/get_show_main_loader']
    },

    login_info() {
      return this.$store.getters['store_auth/get_login_info']
    },

    login_info_type() {
      return this.$store.getters['store_auth/get_login_info_type']
    }

  },

  methods: {

    handleLogin () {

      if (this.$refs.form.validate()) {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        const { username, password } = this

          authentication.LoginRoutine({ username, password }).then(() => {

            if (this.$store.getters['store_auth/get_password_changed']) {

              this.$router.push(this.$store.getters['store_auth/get_redirect_url'])

            } else {

              this.$router.push('/change-password')

            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        })
        
      }

    },

  }

}
</script>
